import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// 公共css
Vue.config.productionTip = false
import GlobalCss from '@/assets/css/main.css'
Vue.use(GlobalCss);
import animate from '@/assets/css/animate.css'
Vue.use(animate);
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.prototype.windowSysInfo = {
    bwid: document.documentElement.clientWidth,
    bhig: document.documentElement.clientHeight,
    wwid: document.documentElement.offsetWidth,
    whig: document.documentElement.offsetHeight,
    ihig: window.innerHeight,
    shig: window.screen.height,
    stop: window.screenTop,
};
const requireComponent = require.context(
  // 其组件目录的相对路径
  './components',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /Base[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      // 获取和目录深度无关的文件名
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
