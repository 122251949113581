import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: resolve => {require(['@/pages/index'], resolve)},
      meta:{
        title:'郑州约克计算机技术有限公司'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: resolve => {require(['@/pages/login'], resolve)},
      meta:{
        title:'登录'
      }
    },
    {
      path: '/overview',
      name: 'overview',
      component: resolve => {require(['@/pages/overview'], resolve)},
      meta:{
        title:'企业概述'
      }
    },
    {
      path: '/dynamic',
      name: 'dynamic',
      component: resolve => {require(['@/pages/dynamic'], resolve)},
      meta:{
        title:'企业动态'
      }
    },
    {
      path: '/news',
      name: 'news',
      component: resolve => {require(['@/pages/news'], resolve)},
      meta:{
        title:'新闻资讯'
      }
    },
    {
      path: '/about',
      name: 'about',
      component: resolve => {require(['@/pages/about'], resolve)},
      meta:{
        title:'关于我们'
      }
    },
    { path: '*', component: resolve => {require(['@/pages/404'], resolve)}}
  ],
  mode:'hash'
})
